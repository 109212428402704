// useIsMobile.ts
import { useEffect, useState } from 'react';

const useIsMobile = (mobileWidthThreshold: number = 599) => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const checkIsMobile = () => {
        const userAgent = navigator.userAgent || '';
        const mobileRegex = /Mobi|Android/i;
        const isMobileDevice = mobileRegex.test(userAgent);
        const isSmallScreen = window.innerWidth <= mobileWidthThreshold;

        setIsMobile(isMobileDevice || isSmallScreen);
    };

    useEffect(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;
