import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { ICreateMessage, ICreatePostOrMessagePayload, addChatToPost, getCurrentPost, startPost } from "../../store/editingPostSlice";
import { getCurrentUser } from "../../store/currentUserSlice";
import PostListItem from "../../global/post-list-item/post-list-item";
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { IUpdatePost, updatePost } from "../../store/postSlice";
import { editingPostSlice } from "../../store/editingPostSlice"
import SendIcon from '@mui/icons-material/Send';
import styled from "styled-components";
import { ApiStatus } from "../../models/redux/APIStatus";
import CenterCircularSpinner from "../../global/spinner/CenterCircularSpinner";
import { getAllModelsThunk } from "../../store/modelSlice";
import useIsMobile from "../../custom_hooks/isMobile";

const MessageInputDiv = styled('div')({
    position: 'fixed',
    bottom: 0,
    width: '80%',
    backgroundColor: 'white',
})

const MessageInputDivMobile = styled('div')({
    position: 'fixed',
    bottom: 50,
    width: '90%',
    marginTop: '50px',
    backgroundColor: 'white',
})

const ChatListContainer = styled('div')({
    flex: 1,
    overflowY: 'auto',
    paddingTop: '10px',
})

const MainChatContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
})

const HeaderContainer = styled('div')({
    width: '90%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    marginTop: '10px',
    marginBottom: '10px',
    justifyContent: 'space-between'
});

const StyledSubmitButton = styled(Button)({
    padding: '10px',
    marginLeft: '20px',
})

const NewPostModal: FC = () => {

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useAppDispatch();
    const [content, setContent] = useState("");
    const [isDoneEditingPost, setIsDoneEditingPost] = useState(false);
    const currentEditingPost = useAppSelector(state => state.currentPost.post);
    const fetchCurrentEditingPostStatus = useAppSelector(state => state.currentPost.fetchCurrentPostStatus);
    const askQuestionStatus = useAppSelector(state => state.currentPost.askQuestionStatus);
    const [selectedModel, setSelectedModel] = useState<string>("gemini-1.5-flash");

    const models = useAppSelector(state => state.models.models);
    const modelsFetchStatus = useAppSelector(state => state.models.modelFetchStatus);

    const user = useAppSelector(getCurrentUser);
    const isMobile = useIsMobile();
    const Div = isMobile ? MessageInputDivMobile : MessageInputDiv;

    const chatError = useAppSelector((state) => state.currentPost.error);
    const postError = useAppSelector((state) => state.posts.error);
    // const profilePosts: IProfilePosts = useSelector((state: RootState) => getProfilePosts(state, user.userHandle));

    useEffect(() => {
        const getCurrentEditingPostAndModels = async () => {
            getAccessTokenSilently().then(bearerToken => {
                dispatch(getAllModelsThunk(bearerToken))
                dispatch(getCurrentPost(bearerToken));
            })
        };
        if (fetchCurrentEditingPostStatus === 'idle' || modelsFetchStatus == ApiStatus.IDLE) {
            getCurrentEditingPostAndModels();
        }
    }, [])

    useEffect(() => {
        scrollToBottom();
    }, [currentEditingPost]);

    const messagesEndRef = useRef<HTMLInputElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const handleAskQuestion = () => {
        setContent("");
        getAccessTokenSilently().then(bearerToken => {
            const request = {
                message: content,
                bearerToken: bearerToken
            } as ICreatePostOrMessagePayload

            const addMessageRequest = {
                message: content,
                userHandle: user.userHandle,
                userName: user.userName,
            } as ICreateMessage;

            dispatch(editingPostSlice.actions.addMessage(addMessageRequest));
            if (currentEditingPost == null || currentEditingPost.llmInformation == null || currentEditingPost.llmInformation.messages == null || currentEditingPost.llmInformation.messages.length == 0) {
                request.modelName = selectedModel;
                dispatch(startPost(request));
            } else {
                dispatch(addChatToPost(request));
            }

        })
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !event.shiftKey && content.length > 0) {
            event.preventDefault(); // Prevents the newline character
            handleAskQuestion(); // Call your submission function
        }
    };

    const handlePostPost = () => {

        getAccessTokenSilently().then(bearerToken => {
            const updatedPostRequest: IUpdatePost = {
                caption: content,
                status: "COMPLETE",
                bearerToken: bearerToken
            };

            dispatch(updatePost(updatedPostRequest));

            dispatch(editingPostSlice.actions.clearEditingPost());

        })
    }

    const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
    }

    const handleModelChange = (event: SelectChangeEvent) => {
        setSelectedModel(event.target.value);
    };

    if (
        modelsFetchStatus == ApiStatus.IDLE ||
        modelsFetchStatus == ApiStatus.FETCHING ||
        fetchCurrentEditingPostStatus == 'idle' ||
        fetchCurrentEditingPostStatus == 'loading'
    ) {
        return <CenterCircularSpinner />
    }

    return (
        <MainChatContainer>
            <HeaderContainer>
                <StyledSubmitButton onClick={handlePostPost} variant="contained" disabled={currentEditingPost.llmInformation == null}>Share Conversation</StyledSubmitButton>
                <FormControl sx={{ minWidth: '150px' }} size="medium" disabled={currentEditingPost.id != null}>
                    <InputLabel id="demo-simple-select-label">Model</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentEditingPost.id == null ? selectedModel : currentEditingPost.llmInformation.model}
                        label="Model"
                        onChange={handleModelChange}
                    >
                        {models.map((model) => (
                            <MenuItem key={model.name} value={model.name}>
                                {model.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </HeaderContainer>


            <ChatListContainer>
                {
                    currentEditingPost.llmInformation != null &&
                    <Box sx={{ marginBottom: '80px', width: '90%' }}>
                        <PostListItem
                            post={currentEditingPost}
                            isEditingPost={true}
                        />
                        {askQuestionStatus == ApiStatus.FETCHING &&
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }
                        <div ref={messagesEndRef} />
                    </Box>


                }

            </ChatListContainer>
            {
                isMobile && <Box sx={{ height: '50px', visibility: 'hidden' }} />
            }
            <Div>

                <TextField
                    sx={{ width: isMobile ? '100%' : '85%' }}
                    InputProps={{
                        style:
                        {
                            fontSize: 18,
                            margin: '10px',
                            borderRadius: '10px',

                        },
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={() => handleAskQuestion()} disabled={askQuestionStatus == ApiStatus.FETCHING || content.length == 0}>
                                    <SendIcon color="primary" />
                                </IconButton>

                            </InputAdornment>
                    }}
                    placeholder={!isDoneEditingPost ? "What question can I answer?" : "Optional: Enter a caption for the post"}
                    onKeyDown={handleKeyDown}
                    minRows={1}
                    multiline
                    fullWidth
                    onChange={handleTextFieldChange}
                    value={content}
                    disabled={askQuestionStatus == ApiStatus.FETCHING}
                />

            </Div>
            {/* <div className="button-border">
                <Button className="submit-button" variant="contained" onClick={() => !isDoneEditingPost ? handleAskQuestion() : handlePostPost()}>{!isDoneEditingPost ? "Ask Question" : "Create Post"}</Button>
            </div>

            {!isDoneEditingPost && <div className="button-border">
                <Button className="submit-button" variant="contained" onClick={() => setIsDoneEditingPost(true)}>Done Asking Questions?</Button>
            </div>} */}

        </MainChatContainer >
    )

}

export default NewPostModal;