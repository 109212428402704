import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import './post-list-item.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { IPostDTO } from "../../models/post/IPostDTO";
import Message2 from "../post-list-message/post-list-message";
import moment from "moment-timezone";
import { Link } from "react-router-dom";


interface IPostProps {
    post: IPostDTO,
    isEditingPost: boolean
}

const PostListItem: FC<IPostProps> = (props: IPostProps) => {

    const getUserTime = (utcDate: string) => {
        // Convert UTC date to user's local time zone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Convert UTC date to local time zone
        const localDate = moment.utc(utcDate).tz(timeZone).format('YYYY-MM-DD hh:mm:ss A');

        return localDate;
    };

    return (
        <div className="post-border" style={props.isEditingPost ? { borderBottom: 'none' } : {}}>
            {!props.isEditingPost && <Grid container className="" >
                <Grid item xs={1} className="">

                </Grid>
                <Grid item xs={11}>
                    <div className="header">
                        <Link to={`/${props.post.userInformation.userHandle}`} className="clickable username" style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold' }}>
                            <Typography variant="body1" sx={{ marginBottom: '10px' }} className="text padding">
                                @{props.post.userInformation.userHandle}
                            </Typography>
                        </Link>                        <Typography variant="body1" className="text padding">·</Typography>
                        <Typography variant="body1" className="text padding">{getUserTime(props.post.postInformation.datePosted!)}</Typography>
                        <div className="icon-more">
                            <MoreHorizIcon />
                        </div>
                    </div>
                </Grid>
            </Grid>
            }
            {props.post.llmInformation.messages.map((chat, index) => (
                <Message2
                    key={index}
                    chat={chat}
                    userHandle={props.post.userInformation.userHandle}
                    userName={props.post.userInformation.userName}
                />
            ))}



            <Grid container className="" display={(props.isEditingPost || props.post.postInformation.caption == null || props.post.postInformation.caption.length == 0) ? 'none' : ''}>

                <Grid item xs={1}>
                    <div className="">
                        <img height={40} width={40} src="/man.svg" alt="logo" className="profile-picture" />
                    </div>
                </Grid>
                {!props.isEditingPost &&
                    <Grid item xs={11}>
                        <p className="content-text"><b>Caption:</b>{props.post.postInformation.caption}</p>

                    </Grid>
                }
            </Grid>

            <Grid container sx={{ marginTop: '20px' }} display={props.isEditingPost ? 'none' : ''}>
                <Grid item xs={1}>

                </Grid>
                <Grid item xs={10}>
                    <div className="footer">
                        <ChatBubbleOutlineIcon style={{ fontSize: 18 }} />
                        <BookmarkBorderIcon style={{ fontSize: 18 }} />
                        <FavoriteBorderIcon style={{ fontSize: 18 }} />
                        <ThumbDownOffAltIcon style={{ fontSize: 18 }} />
                    </div>
                </Grid>
            </Grid>


        </div>
    );
}

export default PostListItem;
