import axios, { AxiosResponse } from 'axios';

const API_BASE_URL = 'http:localhost:3000';

const createAxiosInstance = (bearerToken?: string) => {
    let instance;

    if (bearerToken != null) {
        instance = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                'Authorization': `Bearer ${bearerToken}`
            }
        });
    } else {
        instance = axios.create({
            baseURL: API_BASE_URL,
        });
    }
    return instance;
};

const handleResponse = (response: AxiosResponse<any, any>) => {
    return response.data;
};

const handleError = () => {
    console.log("Error making the api call")
    throw new Error('An error occurred while making the API call.');
};

export const get = async (url: string, params = {}, bearerToken?: string) => {
    const axiosInstance = createAxiosInstance(bearerToken);
    try {
        const response = await axiosInstance.get(url, { params });
        return handleResponse(response);
    } catch (error) {
        console.log(error);
        return handleError();
    }
};

export const post = async (url: string, data = {}, bearerToken?: string) => {
    const axiosInstance = createAxiosInstance(bearerToken);

    try {
        const response = await axiosInstance.post(url, data);
        return handleResponse(response);
    } catch (error) {
        return handleError();
    }
};

// export const put = async (url: string, data = {}) => {
//     const axiosInstance = createAxiosInstance();

//     try {
//         const response = await axiosInstance.put(url, data);
//         return handleResponse(response);
//     } catch (error) {
//         return handleError();
//     }
// };

export const patch = async (url: string, data = {}, bearerToken: string) => {
    const axiosInstance = createAxiosInstance(bearerToken);

    try {
        const response = await axiosInstance.patch(url, data);
        return handleResponse(response);
    } catch (error) {
        return handleError();
    }
};

// export const del = async (url: string) => {
//     const axiosInstance = createAxiosInstance();

//     try {
//         const response = await axiosInstance.delete(url);
//         return handleResponse(response);
//     } catch (error) {
//         return handleError();
//     }
// };