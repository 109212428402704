import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";
import LoginModal from "../login-modal/login-modal";
import { useAppSelector } from "../../store/hooks";
import CenterCircularSpinner from "../../global/spinner/CenterCircularSpinner";
import React from "react";
import LoginModalV2 from "../login-modal-v2/loginModalv2";

interface IRequireAuthenticationProps {
    children: any;
}

const RequireAuthentication: FC<IRequireAuthenticationProps> = (props: IRequireAuthenticationProps) => {
    const { isAuthenticated } = useAuth0();

    const [openLoginModal, setOpenLoginModal] = React.useState(true);

    // useEffect(() => {
    //     if (userName === null || userHandle === null) {
    //         navigate("/profile/setup");
    //         return;
    //     }
    // }, [userName, userHandle])

    if (isAuthenticated) {

        return props.children;
    }
    return <LoginModalV2 open={openLoginModal} setOpen={setOpenLoginModal} />
}

export default RequireAuthentication;