import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from './store/hooks';

const Auth0ProviderWithNavigate = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState: any) => {
        console.log("appState?.returnTo" + appState?.returnTo)
        if (appState?.returnTo === '/undefined') {
            navigate(`/`, { replace: true });
        } else {
            navigate(appState?.returnTo || "/", { replace: true });
        }
    };

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: `${process.env.REACT_APP_ORCHESTRATOR_URL!}`,
                scope: 'openid profile email offline_access',
            }}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithNavigate;
