import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import "./login-button.css"

interface LoginButtonParams {
    useReturnTo?: boolean
}

const LoginButton: React.FC<LoginButtonParams> = ({ useReturnTo }) => {
    const { loginWithRedirect } = useAuth0();

    async function login() {
        if (useReturnTo) {
            await loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname
                }
            });
        }
        await loginWithRedirect;
    }

    return <Button className="login-button" variant="contained" onClick={() => login()}>Log In</Button>;
};

export default LoginButton;