import './App.css';
import Grid from '@mui/material/Grid';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SideDrawer from './components/drawer/drawer';
import Profile from './pages/profile/profile';
import Bookmarks from './pages/bookmarks/Bookmark';
import RequireAuthentication from './pages/authenticated-page/require-authentication';
import LoginModal from './pages/login-modal/login-modal';
import Home from './pages/home/home';
import NewPostModal from './pages/new-post-modal-v2/new-post-modal';
import ProfileSetup from './pages/profile/setup/profile-setup';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { fetchUserInformation } from './store/currentUserSlice';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import Feed from './pages/feed/feed';
import CenterCircularSpinner from './global/spinner/CenterCircularSpinner';
import NotFound from './pages/not-found/NotFound';
import useIsMobile from './custom_hooks/isMobile';
import BottomNav from './components/bottom-nav/bottomNav';

function App() {

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: '#49a7f4',
            fontWeight: 'bold',
            color: 'white',
            fontSize: '15px',
            borderRadius: '20px',
            whiteSpace: 'normal',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#49a7f4', // Keep the same color on hover
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#49a7f4', // Replace this with your desired primary color
      },
    },
  });

  const dispatch = useAppDispatch();
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const userName = useAppSelector(state => state.currentUser.user.userName);
  const userHandle = useAppSelector(state => state.currentUser.user.userHandle);
  const status = useAppSelector(state => state.currentUser.status);
  const errorCount = useAppSelector(state => state.currentUser.errorCount);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  useEffect(() => {
    const getLoggedInUser = async () => {
      getAccessTokenSilently().then(bearerToken => {
        dispatch(fetchUserInformation(bearerToken));
      })
    }
    if (status !== 'succeeded' && isAuthenticated && errorCount < 5) {
      getLoggedInUser();
    }

  }, [isAuthenticated, status, userName, userHandle])

  useEffect(() => {
    if (location.pathname !== "/profile/setup" && status === 'succeeded' && (userName === null || userHandle === null)) {
      navigate("/profile/setup");
    }

  }, [isAuthenticated, status, userName, userHandle])

  if ((isAuthenticated && (status === 'loading' || status === 'idle')) || isLoading) {
    return <CenterCircularSpinner />
  }

  console.log(errorCount)
  if (status !== 'succeeded' && isAuthenticated && errorCount >= 5) {
    return <h1>Sorry something went wrong, please refresh the page</h1>
  }

  console.log(window.location.pathname)
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Grid container>
          {!isMobile &&
            <Grid item lg={3} md={2} sm={2} xs={0} sx={{ position: 'sticky', zIndex: 1000 }}>
              <SideDrawer />
            </Grid>
          }
          <Grid item lg={9} md={10} sm={7} xs={12} sx={{ position: 'relative' }}>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/home" element={<RequireAuthentication><Feed /></RequireAuthentication>}></Route>
              <Route path="/post/new" element={<RequireAuthentication><NewPostModal /></RequireAuthentication>}></Route>
              <Route path="/login" element={<LoginModal />}></Route>
              <Route path="/:userHandle" >
                <Route index element={<RequireAuthentication><Profile /></RequireAuthentication>}></Route>
                <Route path="likes" element={<RequireAuthentication><Profile /></RequireAuthentication>}></Route>
                <Route path="dislikes" element={<RequireAuthentication><Profile /></RequireAuthentication>}></Route>
                <Route path="setup" element={<ProfileSetup />}></Route>
              </Route>
              <Route path="*" element={<NotFound />} />

              {/* <Route path="/bookmarks" element={<RequireAuthentication><Bookmarks /></RequireAuthentication>}></Route> */}
            </Routes>
          </Grid>
          {isMobile && location.pathname !== '/post/new' && (
            <Grid item xs={12} sx={{ height: '100px', visibility: 'hidden' }} />
          )}
          {isMobile &&
            <Grid item sm={0} xs={12} >
              <BottomNav />
            </Grid>
          }
        </Grid >
      </ThemeProvider>
    </Box>

  );
}

export default App;
