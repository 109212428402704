import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// Define the type for t

// Define the CenteredLoader functional component
const CenterCircularSpinner: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <CircularProgress size={"100px"} />
        </Box>
    );
}

export default CenterCircularSpinner;
