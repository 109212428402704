import { Button, Container } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useEffect } from "react"
import FollowCount from "../../components/profile/follow_count/follow-count";
import Header from "../../components/profile/header/header";
import ProfileNavBar from "../../components/profile/profile_nav_bar/profile-nav-bar";
import "./profile.css"
import { useLocation } from "react-router";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../store/hooks";
import { IProfilePosts, fetchProfile, fetchProfilePosts, getProfile, getProfilePosts } from "../../store/postSlice";
import { IProfileDTO } from "../../models/profile/profile";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import moment from "moment";
import { getCurrentUser } from "../../store/currentUserSlice";

const ProfilePageContainer = styled(Container)({
})

const Profile: FC = () => {
    const { pathname } = useLocation();
    const userHandle = pathname.split("/")[1];
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useAppDispatch();

    const profileInfo: undefined | IProfileDTO = useSelector((state: RootState) => getProfile(state, userHandle));
    const profilePosts: undefined | IProfilePosts = useSelector((state: RootState) => getProfilePosts(state, userHandle));
    const currentUser = useSelector((state: RootState) => getCurrentUser(state));

    useEffect(() => {
        getAccessTokenSilently().then(bearerToken2 => {
            dispatch(fetchProfile({ userHandle: userHandle, bearerToken: bearerToken2 }))
            dispatch(fetchProfilePosts({ userHandle: userHandle, bearerToken: bearerToken2 }));
        })
    }, [userHandle])

    const getDateJoinedTime = (utcDate: string) => {
        // Convert UTC date to user's local time zone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Convert UTC date to local time zone
        const localDate = moment.utc(utcDate).tz(timeZone).format('MMMM YYYY');

        return localDate;
    };

    const buttonTextShown = () => {
        if (profileInfo) {
            if (profileInfo.userHandle == currentUser.userHandle) {
                return "Edit Profile";
            } else {
                return "Follow"
            }
        }
        return "";
    }

    return (
        <ProfilePageContainer maxWidth="lg">
            <Stack spacing={0}>
                <Header tweetCount={profilePosts?.posts?.posts?.length ?? ""} userName={profileInfo ? profileInfo.userName : ""} />
                <img height={225} src="/background.jpg" alt="logo" />
                <img height={150} width={150} src="/man.svg" alt="logo" className="profile-pic" />
                <div>
                    <Button className="edit-profile-button" sx={{ display: !profileInfo ? 'none' : 'block' }} variant="outlined">{buttonTextShown()}</Button>
                </div>
                <p className="profile-text profile-bold profile-name">{profileInfo ? profileInfo.userName : ""}</p>
                <p className="profile-text">@{userHandle}</p>
                <p className="profile-text profile-date-joined">{profileInfo ? `Joined ${getDateJoinedTime(profileInfo.dateCreated)}` : ""}</p>
                <FollowCount />
                <ProfileNavBar userHandle={userHandle} />
            </Stack>
        </ProfilePageContainer>
    )
}

export default Profile;