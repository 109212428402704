import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import IPersonDTO from '../models/person/IPersonDTO';
import { getOrCreatePerson, updateCustomer } from '../api/user-api-client';
import IUpdatePersonDTO from '../models/person/IUpdatePersonDTO';
import { RootState } from './store';

interface IPayload {
    userName: string,
    userHandle: string
}

export interface IUpdatedPersonPayload {
    bearerToken: string,
    personDTO: IUpdatePersonDTO
}

const initialState = {
    user: {

    } as IPersonDTO,
    status: 'idle',
    error: "",
    errorCount: 0,
}

export const userSlice = createSlice({
    name: 'currentUser',
    initialState: initialState,
    reducers: {
        updateUserName: (state, action: PayloadAction<IPayload>) => {
            state.user.userName = action.payload.userName;
            state.user.userHandle = action.payload.userHandle;
        },

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchUserInformation.fulfilled, (state, action) => {
            state.user.userHandle = action.payload.userHandle;
            state.user.userName = action.payload.userName;
            state.status = 'succeeded'
            // userSlice.caseReducers.updateUserName(state, action);

        })
            .addCase(fetchUserInformation.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message as string;
                state.errorCount += 1;
            })
            .addCase(fetchUserInformation.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateUserInformation.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.user.userHandle = action.payload.userHandle;
                state.user.userName = action.payload.userName;
                state.user.lastName = action.payload.lastName;
                state.user.firstName = action.payload.firstName;

            })
            .addCase(updateUserInformation.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message as string;
            })
    },
})

export const fetchUserInformation = createAsyncThunk('user/fetchUser', async (bearerToken: string) => {
    const response = await getOrCreatePerson(bearerToken);
    return response;
});

export const updateUserInformation = createAsyncThunk('user/updateUser', async (request: IUpdatedPersonPayload) => {
    const response = await updateCustomer(request.personDTO, request.bearerToken);
    return response;
});

export const getCurrentUser = (state: RootState) => {
    return state.currentUser.user;
}

// Action creators are generated for each case reducer function
//fix this in other classes
//export const { updateUserName } = userSlice.actions;

export default userSlice.reducer;