import { FC } from "react";
import PostList from "../../../global/post-list/post-list";
import { useSelector } from "react-redux";
import { IProfilePosts, getProfilePosts } from "../../../store/postSlice";
import { RootState } from "../../../store/store";
import CenterCircularSpinner from "../../../global/spinner/CenterCircularSpinner";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { getCurrentUser } from "../../../store/currentUserSlice";
import { ApiStatus } from "../../../models/redux/APIStatus";

interface IPreviousPostsProps {
    userHandle: string;
}
const PreviousPosts: FC<IPreviousPostsProps> = (props: IPreviousPostsProps) => {
    const profilePosts: undefined | IProfilePosts = useSelector((state: RootState) => getProfilePosts(state, props.userHandle));
    const profilePostsStatus = useSelector((state: RootState) => state.posts.fetchProfilePostStatus);
    const currentUser = useSelector((state: RootState) => getCurrentUser(state));
    const currentUserStatus = useSelector((state: RootState) => state.currentUser.status);
    const navigate = useNavigate();

    const handleCreatePost = () => {
        navigate("/post/new");
    }

    // if ((profilePosts !== undefined && profilePosts.posts === undefined)
    //     || currentUserStatus === 'loading'
    //     || currentUserStatus === 'idle') {
    //     return <CenterCircularSpinner />
    // }

    if ((profilePosts !== undefined && profilePosts.posts === undefined) ||
        profilePostsStatus === ApiStatus.FETCHING
        || profilePostsStatus === ApiStatus.IDLE || currentUserStatus === 'loading'
        || currentUserStatus === 'idle') {
        return <CenterCircularSpinner />
    }

    if (profilePosts === undefined) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                marginTop={5}
            >
                <Typography variant="h5" gutterBottom>
                    User not found
                </Typography>
            </Box>
        )
    }

    if (profilePosts !== undefined && profilePosts.posts.posts.length === 0) {
        if (props.userHandle === currentUser.userHandle) {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    marginTop={5}
                >
                    <Typography variant="h5" gutterBottom>
                        You have no posts. Go and create some!
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreatePost}
                    >
                        Create Post
                    </Button>
                </Box>
            )
        } else {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    marginTop={5}
                >
                    <Typography variant="h5" gutterBottom>
                        User has no posts
                    </Typography>
                </Box>
            )
        }

    }
    return <PostList posts={profilePosts?.posts?.posts}
    />
}

export default PreviousPosts;