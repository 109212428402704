import { Container, Stack } from "@mui/material";
import { FC } from "react";
import PostListItem from "../post-list-item/post-list-item";
import { IPostDTO } from "../../models/post/IPostDTO";

interface IPostListProps {
    posts?: IPostDTO[]
}

const PostList: FC<IPostListProps> = (props: IPostListProps) => {
    return (
        <Container maxWidth='lg' >
            {props.posts && props.posts.map((post, index) => {
                return <PostListItem
                    key={index}
                    post={post}
                    isEditingPost={false}
                />
            })}
        </Container>
    );
}

export default PostList;