import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllModels } from "../api/model-api-client";
import { ApiStatus } from "../models/redux/APIStatus";
import IModelDTO from "../models/model/IModelDTO";

export const modelSlice = createSlice({
    name: "model",
    initialState: {
        models: [] as IModelDTO[],
        error: "",
        modelFetchStatus: ApiStatus.IDLE
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllModelsThunk.fulfilled, (state, action) => {
            state.models = action.payload;
            state.modelFetchStatus = ApiStatus.FETCHED;
        }).addCase(getAllModelsThunk.rejected, (state, action) => {
            state.error = action.error.message as string;
        }).addCase(getAllModelsThunk.pending, (state, action) => {
            state.modelFetchStatus = ApiStatus.FETCHING;
        })
    }
});

export const getAllModelsThunk = createAsyncThunk('model/getAllModels', async (bearerToken: string) => {

    const response = await getAllModels(bearerToken);
    return response;
});

export default modelSlice.reducer;