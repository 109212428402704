import { configureStore } from '@reduxjs/toolkit'
import userReducer from './currentUserSlice';
import postReducer from './editingPostSlice';
import postReducer2 from './postSlice';
import modelSlice from './modelSlice';

export const store = configureStore({
    reducer: {
        currentUser: userReducer,
        currentPost: postReducer,
        posts: postReducer2,
        models: modelSlice
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch