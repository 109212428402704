import { FC, ReactNode, SetStateAction } from "react";
import './modal.css'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router";

interface IModalProps {
    children: React.ReactNode
}

const Modal: FC<IModalProps> = (props: IModalProps) => {
    const navigate = useNavigate();
    function closeModal() {
        navigate(-1);
    }

    return (
        <div>
            <div className={"dark-bg"} onClick={() => closeModal()} />
            <div className={"centered"}>
                <div className={"modal"}>
                    <div className="close-icon-border" onClick={() => closeModal()}>
                        <CloseIcon className="close-icon" />
                    </div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal;