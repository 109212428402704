import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUpdatePostAPI, getRandomPost, getUsersPostsByUserHandle, updatePostAPICall } from "../api/post-api-client";
import { IPostListDTO } from "../models/post/IPostListDTO";
import { RootState } from "./store";
import { IPostDTO } from "../models/post/IPostDTO";
import { IProfileDTO } from "../models/profile/profile";
import { getUserProfile } from "../api/profile-api-client";
import { ApiStatus } from "../models/redux/APIStatus";

export interface IUpdatePost {
    status?: string;
    caption?: string;
    bearerToken: string;
}

export interface IGetUsersPosts {
    userHandle: string;
    pageNumber?: number;
    bearerToken: string;
}

export interface IGetProfile {
    userHandle: string;
    bearerToken: string;
}

interface IProfileMap {
    [userId: string]: IProfilePosts
}

export interface IProfilePosts {
    posts: IPostListDTO
    profile?: IProfileDTO
}

export const postSlice = createSlice({
    name: "post",
    initialState: {
        randomPost: {} as IPostDTO,
        fetchRandomPostStatus: ApiStatus.IDLE,
        fetchProfilePostStatus: ApiStatus.IDLE,
        profilePosts: {} as IProfileMap,
        error: ""
    },
    reducers: {
        addPostToProfile: (state, action: PayloadAction<IPostDTO>) => {
            const userHandle: string = action.payload.userInformation.userHandle!;
            state.profilePosts[userHandle].posts.posts = [action.payload, ...state.profilePosts[userHandle].posts.posts];
        },
        setFetchRandomPostStatus: (state, action: PayloadAction<ApiStatus>) => {
            state.fetchRandomPostStatus = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfilePosts.fulfilled, (state, action) => {

            state.profilePosts[action.payload.request.userHandle] = {
                ...state.profilePosts[action.payload.request.userHandle],
                posts: action.payload.response,
            }
            state.fetchProfilePostStatus = ApiStatus.FETCHED;
        }).addCase(fetchProfilePosts.pending, (state, action) => {
            state.fetchProfilePostStatus = ApiStatus.FETCHING;
        }).addCase(updatePost.rejected, (state, action) => {
            state.error = action.error.message as string;
        }).addCase(updatePost.fulfilled, (state, action) => {
            const userHandle = action.payload.userInformation.userHandle!;
            if (state.profilePosts[userHandle]) {
                postSlice.caseReducers.addPostToProfile(state, action);
            }
            state.error = "";
        }).addCase(fetchProfile.fulfilled, (state, action) => {
            state.profilePosts[action.payload.request.userHandle] = {
                ...state.profilePosts[action.payload.request.userHandle],
                profile: action.payload.response,
            }
            state.fetchProfilePostStatus = ApiStatus.FETCHED;
            state.error = "";
        }).addCase(fetchRandomPost.fulfilled, (state, action) => {
            state.randomPost = action.payload.response;
            state.error = "";
            state.fetchRandomPostStatus = ApiStatus.FETCHED
        }).addCase(fetchRandomPost.pending, (state, action) => {
            state.fetchRandomPostStatus = ApiStatus.FETCHING
        })
    }
});

export const updatePost = createAsyncThunk('post/updatePost', async (updatePost: IUpdatePost) => {
    const updateRequest: IUpdatePostAPI = {
        postStatus: updatePost.status,
        caption: updatePost.caption
    }
    const response = await updatePostAPICall(updatePost.bearerToken, updateRequest);
    return response;
});

export const fetchProfilePosts = createAsyncThunk('posts/getProfilePosts', async (getUsersPostsRequest: IGetUsersPosts) => {
    const response = await getUsersPostsByUserHandle(getUsersPostsRequest.userHandle, getUsersPostsRequest.bearerToken, 1);
    return {
        response: response,
        request: getUsersPostsRequest
    };
});

export const fetchProfile = createAsyncThunk('posts/profile', async (getUsersPostsRequest: IGetProfile) => {
    const response = await getUserProfile(getUsersPostsRequest.userHandle, getUsersPostsRequest.bearerToken);

    return {
        response: response,
        request: getUsersPostsRequest
    };
});

export const fetchRandomPost = createAsyncThunk('posts/random', async (bearerToken: string) => {
    const response = await getRandomPost(bearerToken);

    return {
        response: response,
        request: bearerToken
    };
});

export const getProfilePosts = (state: RootState, userHandle: string) => {
    return state.posts.profilePosts[userHandle];
}

export const getProfile = (state: RootState, userHandle: string) => {
    return state.posts.profilePosts[userHandle]?.profile;
}

export default postSlice.reducer;