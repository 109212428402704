import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoginButton from '../../components/login-button/login-button';
import { useNavigate } from 'react-router';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    maxWidth: '500px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center items horizontally
};

interface loginModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginModalV2: React.FC<loginModalProps> = ({ open, setOpen }) => {
    const navigate = useNavigate();

    const handleClose = () => navigate(-1);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/* Back icon button */}
                    <IconButton onClick={handleClose} aria-label="back" sx={{ alignSelf: 'flex-start' }}>
                        <ArrowBackIcon />
                    </IconButton>

                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center" sx={{ marginY: '30px' }}>
                        Please login or signup to use the site
                    </Typography>
                    <LoginButton useReturnTo />
                </Box>
            </Modal>
        </div>
    );
}

export default LoginModalV2;
