import { IPostDTO } from "../models/post/IPostDTO";
import { IPostListDTO } from "../models/post/IPostListDTO";
import { post, get } from "./api-client";

const SERVER_URL = process.env.REACT_APP_ORCHESTRATOR_URL
const POST_SERVER_URL = `${SERVER_URL}/v1/post`

export interface IAskQuestion {
    content: string;
}

export interface IUpdatePostAPI {
    postStatus?: string;
    caption?: string;
}

export const createPost = (bearerToken: string, body: IAskQuestion, model: string): Promise<IPostDTO> => {
    return post(`${POST_SERVER_URL}?model=${model}`, body, bearerToken);
}

export const createPostChat = (bearerToken: string, body: IAskQuestion): Promise<IPostDTO> => {
    return post(`${POST_SERVER_URL}/chat`, body, bearerToken);
}

export const updatePostAPICall = (bearerToken: string, body: IUpdatePostAPI): Promise<IPostDTO> => {
    return post(`${POST_SERVER_URL}/editing`, body, bearerToken);
}

export const getCurrentEditingPost = (bearerToken: string): Promise<IPostDTO> => {
    return get(`${POST_SERVER_URL}/editing`, undefined, bearerToken);
}

export const getRandomPost = (bearerToken: string): Promise<IPostDTO> => {
    return get(`${POST_SERVER_URL}/random`, undefined, bearerToken);
}

export const getUsersPostsByUserHandle = (userHandle: string, bearerToken: string, currentPage: number): Promise<IPostListDTO> => {
    return get(`${POST_SERVER_URL}s/user/${userHandle}?currentPage=${currentPage}`, undefined, bearerToken);
}
