import { FC, useEffect } from "react";
import PostList from "../../global/post-list/post-list";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ApiStatus } from "../../models/redux/APIStatus";
import CenterCircularSpinner from "../../global/spinner/CenterCircularSpinner";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchRandomPost, postSlice } from "../../store/postSlice";
import { Box, Button, Typography } from "@mui/material";

const Feed: FC = () => {
    const fetchRandomPostStatus = useAppSelector(state => state.posts.fetchRandomPostStatus);
    const randomPost = useAppSelector(state => state.posts.randomPost);
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const getCurrentEditingPostAndModels = async () => {
            getAccessTokenSilently().then(bearerToken => {
                dispatch(fetchRandomPost(bearerToken));
            })
        };
        if (fetchRandomPostStatus === ApiStatus.IDLE) {
            getCurrentEditingPostAndModels();
        }

    }, [fetchRandomPostStatus])

    const findNewRandomPost = () => {
        dispatch(postSlice.actions.setFetchRandomPostStatus(ApiStatus.IDLE));
    }

    if (
        fetchRandomPostStatus == ApiStatus.IDLE ||
        fetchRandomPostStatus == ApiStatus.FETCHING
    ) {
        return <CenterCircularSpinner />
    }

    return (
        <Box my={4}>
            <Typography variant="h4" gutterBottom textAlign="center">
                Random Post
            </Typography>

            <PostList posts={[randomPost]} />

            <Button
                variant="contained"
                color="primary"
                onClick={findNewRandomPost}
                sx={{ display: 'block', margin: '16px auto' }} // centers the button
            >
                New Random Post
            </Button>
        </Box>
    );
}

export default Feed;