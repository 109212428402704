import { Button, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import "./profile-setup.css"
import * as customerClient from "./../../../api/user-api-client";
import IUpdatePersonDTO from "../../../models/person/IUpdatePersonDTO";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../../store/hooks";
import { IUpdatedPersonPayload, updateUserInformation } from "../../../store/currentUserSlice";
import { useNavigate } from "react-router";

const USERNAME_MAX_LENGTH = 20;
const ProfileSetup: FC = () => {

    const dispatch = useAppDispatch();
    const [userName, setUserName] = useState("");
    const [userHandle, setUserHandle] = useState("");

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [errorUserHandle, setErrorUserHandle] = useState(false);
    const [errorMessageUserHandle, setErrorMessageUserHandle] = useState("");

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { getAccessTokenSilently } = useAuth0();

    async function onSubmit() {
        if (userName.length == 0) {
            setErrorMessage("User name can't be blank");
            setError(true);
            return
        } else if (userName.length < 5) {
            setErrorMessage("User name needs to be longer than 5 characters");
            setError(true);
            return
        } else if (userName.length > 20) {
            setErrorMessage("User name needs to be less than or equal to 20 characters");
            setError(true);
            return
        }

        if (userHandle.length == 0) {
            setErrorMessageUserHandle("User handle can't be blank");
            setErrorUserHandle(true);
            return
        } else if (userHandle.length < 5) {
            setErrorMessageUserHandle("User handle needs to be longer than 5 characters");
            setErrorUserHandle(true);
            return
        } else if (userHandle.length > 20) {
            setErrorMessageUserHandle("User handle needs to be less than or equal to 20 characters");
            setErrorUserHandle(true);
            return
        } else if (userHandle.includes(' ')) {
            setErrorMessageUserHandle("User handle cannot have spaces");
            setErrorUserHandle(true);
            return
        }

        const response = await getAccessTokenSilently();

        const available = await customerClient.isUserHandleAvailable(response, userHandle);

        if (!available.isUserHandleAvailable) {
            setErrorMessageUserHandle("User handle already taken choose another one");
            setErrorUserHandle(true);
            return
        }

        setLoading(true);


        const personDTO = {
            userName: userName,
            userHandle: userHandle
        } as IUpdatePersonDTO

        await dispatch(updateUserInformation(
            {
                bearerToken: response,
                personDTO: personDTO
            } as IUpdatedPersonPayload
        ));
        setLoading(false);
        navigate(`/${userHandle}`, { replace: true })

    }

    function onChangeUserName(newVal: string) {
        setUserName(newVal);
        if (newVal.length == USERNAME_MAX_LENGTH) {
            setError(true);
            setErrorMessage("Max Length for username");
        } else if (error && newVal.length < USERNAME_MAX_LENGTH) {
            setError(false);
            setErrorMessage("");
        }
    }

    function onChangeUserHandle(newVal: string) {
        setUserHandle(newVal);
        if (newVal.length == USERNAME_MAX_LENGTH) {
            setErrorUserHandle(true);
            setErrorMessageUserHandle("Max Length for username");
        } else if (errorMessageUserHandle && newVal.length < USERNAME_MAX_LENGTH) {
            setErrorUserHandle(false);
            setErrorMessageUserHandle("");
        }
    }

    return (
        <div className="profile-setup-form">
            <Typography variant="h4">Please select a user name and user handle</Typography>
            <Typography>Username will be displayed on your profile while @userhandle is diplayed in most other places</Typography>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <TextField
                    id="outlined-basic"
                    label="Username"
                    variant="outlined"
                    value={userName}
                    error={error}
                    onChange={(event) => onChangeUserName(event.target.value)}
                    sx={
                        {
                            my: 5,
                            textAlign: "center"
                        }
                    }
                    inputProps={{ maxLength: USERNAME_MAX_LENGTH }}
                    helperText={error ? errorMessage : ""}
                />
                <TextField
                    id="outlined-read-only-input"
                    variant="outlined"
                    label="User Handle"
                    error={errorUserHandle}
                    value={userHandle}
                    onChange={(event) => onChangeUserHandle(event.target.value)}
                    InputProps={{
                        readOnly: false,
                    }}
                    sx={
                        {
                            mt: 5,
                            textAlign: "center"
                        }
                    }
                    helperText={errorUserHandle ? errorMessageUserHandle : ""}
                />
                <FormHelperText>Must Be Unique and no spaces</FormHelperText>

                <Button
                    variant="contained"
                    sx={{ mt: 5 }}
                    onClick={() => { onSubmit() }}
                    disabled={error || errorUserHandle || loading}
                >
                    Submit
                </Button>
            </FormControl>

        </div>
    );
}

export default ProfileSetup;