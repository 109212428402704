import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IChatDTO } from "../models/open_ai/IChatDTO";
import { createPost, createPostChat, getCurrentEditingPost } from "../api/post-api-client";
import { StoreStatus } from "./storeStatus";
import { IPostDTO, LlmInformation, Message } from "../models/post/IPostDTO";
import { ApiStatus } from "../models/redux/APIStatus";

export interface IUpdateEditingPost {
    currentConversation: IChatDTO[],
    currentQuestion: string
}

export interface ICreatePostOrMessagePayload {
    message: string,
    bearerToken: string,
    modelName?: string
}

export interface ICreateMessage {
    message: string,
    userHandle: string,
    userName: string
}

export interface IEditingPostSliceState {
    message: string,
    post: IPostDTO,
    fetchCurrentPostStatus: StoreStatus,
    askQuestionStatus: string
    error: string
}

export const editingPostSlice = createSlice({
    name: "currentPost",
    initialState: {
        message: "",
        post: {
        } as IPostDTO,
        fetchCurrentPostStatus: 'idle',
        askQuestionStatus: ApiStatus.IDLE,
        error: ""
    } as IEditingPostSliceState,
    reducers: {
        addMessage: (state, action: PayloadAction<ICreateMessage>) => {
            if (state.post.llmInformation == null) {
                state.post = {
                    llmInformation: {
                        messages: [] as Message[]
                    } as LlmInformation,
                    userInformation: {
                        userHandle: action.payload.userHandle,
                        userName: action.payload.userName
                    }
                } as IPostDTO
            }
            state.post.llmInformation.messages.push(
                {
                    role: 'user',
                    content: action.payload.message
                }
            )
        },
        updatePost: (state, action: PayloadAction<IUpdateEditingPost>) => {

        },
        clearEditingPost: (state) => {
            state.message = "";
            state.post = {} as IPostDTO;
            state.fetchCurrentPostStatus = 'succeeded';
            state.error = "";
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getCurrentPost.fulfilled, (state, action) => {
            state.fetchCurrentPostStatus = 'succeeded'
            state.post = action.payload;
        }).addCase(getCurrentPost.rejected, (state, action) => {
            state.fetchCurrentPostStatus = 'succeeded'
        }).addCase(startPost.fulfilled, (state, action) => {
            state.askQuestionStatus = ApiStatus.FETCHED;
            state.post = action.payload;
        }).addCase(addChatToPost.fulfilled, (state, action) => {
            state.askQuestionStatus = ApiStatus.FETCHED;
            state.post = action.payload;
        }).addCase(startPost.rejected, (state, action) => {
            state.askQuestionStatus = ApiStatus.FAILED;
            state.error = action.error.message as string;
        }).addCase(addChatToPost.rejected, (state, action) => {
            state.askQuestionStatus = ApiStatus.FAILED;
            state.error = action.error.message as string;
        }).addCase(addChatToPost.pending, (state, action) => {
            state.askQuestionStatus = ApiStatus.FETCHING;
        }).addCase(startPost.pending, (state, action) => {
            state.askQuestionStatus = ApiStatus.FETCHING;
        })
    }
});

export const startPost = createAsyncThunk('currentPost/createPost', async (createPostRequest: ICreatePostOrMessagePayload) => {
    const response = await createPost(createPostRequest.bearerToken, { content: createPostRequest.message }, createPostRequest.modelName!);
    return response;
});

export const addChatToPost = createAsyncThunk('currentPost/addChat', async (createPostMessage: ICreatePostOrMessagePayload) => {
    const response = await createPostChat(createPostMessage.bearerToken, { content: createPostMessage.message });
    return response;
});

export const getCurrentPost = createAsyncThunk('currentPost/getPost', async (bearerToken: string) => {
    const response = await getCurrentEditingPost(bearerToken);
    return response;
});


export default editingPostSlice.reducer;