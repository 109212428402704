import { FC } from "react";
import "./follow-count.css"

const FollowCount: FC = () => {
    return (
        <div className="follow-count-container">
            <p className="follow-count-text follow-count-hover"><b>0</b> Following</p>
            <p className="follow-count-text follow-count-margin follow-count-hover"><b>0</b> Followers</p>
        </div>
    )
}

export default FollowCount;