import React from 'react';
import { Grid, Box, Link, SxProps, Theme, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Markdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Message } from '../../models/post/IPostDTO';
import './post-list-message.css';

interface IPostMessageProps {
    chat: Message,
    userHandle: string | null,
    userName: string | null
}

const Message2 = ({ chat, userHandle, userName }: IPostMessageProps) => {
    const styles: Record<string, SxProps<Theme>> = {
        paragraph: { fontSize: '16px' },
        heading: { fontSize: '20px' },
        blockquote: { fontSize: '18px', borderLeft: '4px solid gray', paddingLeft: '16px', fontStyle: 'italic', marginBottom: '16px' },
        list: { fontSize: '16px', paddingLeft: '16px', marginBottom: '4px', marginTop: '4px' },
        listItem: { fontSize: '16px', marginBottom: '8px', marginLeft: '4px' },
        link: { color: 'primary.main', fontSize: '16px' },
        image: { maxWidth: '100%', height: 'auto', marginBottom: '16px' },
        codeBlock: { fontSize: '14px', backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '4px', marginBottom: '16px' },
        inlineCode: { fontSize: '14px', backgroundColor: '#f5f5f5', padding: '2px 4px' },
        thematicBreak: { border: 'none', borderTop: '1px solid gray', marginY: '24px' },
        emphasis: { fontStyle: 'italic', fontSize: '16px' },
        strong: { fontSize: '18px', fontWeight: 'bold' },

    };
    // Define custom renderers
    const renderers: Components = {
        p: ({ children }) => (
            <Typography sx={styles.paragraph}>{children}</Typography>
        ),
        h1: ({ children }) => (
            <Typography variant="h1" sx={styles.heading}>{children}</Typography>
        ),
        h2: ({ children }) => (
            <Typography variant="h2" sx={styles.heading}>{children}</Typography>
        ),
        h3: ({ children }) => (
            <Typography variant="h3" sx={styles.heading}>{children}</Typography>
        ),
        h4: ({ children }) => (
            <Typography variant="h4" sx={styles.heading}>{children}</Typography>
        ),
        h5: ({ children }) => (
            <Typography variant="h5" sx={styles.heading}>{children}</Typography>
        ),
        h6: ({ children }) => (
            <Typography variant="h6" sx={styles.heading}>{children}</Typography>
        ),
        blockquote: ({ children }) => (
            <Box component="blockquote" sx={styles.blockquote}>{children}</Box>
        ),
        ul: ({ children }) => (
            <Box component="ul" sx={styles.list}>{children}</Box>
        ),
        ol: ({ children }) => (
            <Box component="ol" sx={styles.list}>{children}</Box>
        ),
        li: ({ children }) => (
            <Typography component="li" sx={styles.listItem}>{children}</Typography>
        ),
        a: ({ children, href }) => (
            <Link href={href} sx={styles.link}>{children}</Link>
        ),
        img: ({ src, alt }) => (
            <Box component="img" src={src} alt={alt} sx={styles.image} />
        ),
        code: ({ children }) => {
            // if (inline) {
            //     return (
            //         <Typography component="code" sx={styles.inlineCode}>{children}</Typography>
            //     );
            // }
            return (
                <Box component="pre" sx={styles.codeBlock}>
                    <Typography component="code" sx={{ whiteSpace: 'pre-wrap' }}>{children}</Typography>
                </Box>
            );
        },
        hr: () => (
            <Box component="hr" sx={styles.thematicBreak} />
        ),
        em: ({ children }) => (
            <Typography component="em" sx={styles.emphasis}>{children}</Typography>
        ),
        strong: ({ children }) => (
            <Typography component="strong" sx={styles.strong}>{children}</Typography>
        ),
    };

    const getImageSrc = (role: string) => {
        switch (role) {
            case 'user':
                return '/man.svg';
            case 'assistant':
                return '/gpt.png';
            case 'model':
                return '/gemini_logo.png';
            default:
                return '';
        }
    };

    const getRoleName = (role: string) => {
        switch (role) {
            case 'assistant':
                return 'GPT';
            case 'model':
                return 'Gemini';
            case 'user':
                return userName;
            default:
                return '';
        }
    };

    return (
        <Grid container spacing={2} mb={2}>
            <Grid item xs={1}>
                <img
                    height={40}
                    width={40}
                    src={getImageSrc(chat.role)}
                    alt="logo"
                    className="profile-picture"
                />
            </Grid>
            <Grid item xs={11}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '40px',
                        padding: 1,
                    }}
                >
                    <Markdown
                        className="content-text"
                        components={renderers}
                        remarkPlugins={[remarkGfm]}
                    >
                        {`**${getRoleName(chat.role)}**:\t\t\t${chat.content}`}
                    </Markdown>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Message2;