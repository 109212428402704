import { FC } from "react";
import "./header.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppSelector } from "../../../store/hooks";

interface IHeaderProps {
    tweetCount: number;
    userName: string;
}

const Header: FC<IHeaderProps> = (props: IHeaderProps) => {
    return (
        <div className="profile-header-container">
            <ArrowBackIcon className="profile-header-icon" fontSize="small" />
            <div className="profile-header-text-div ">
                <p className="profile-header-bold profile-header-text">{props.userName}</p>
                <p className="profile-header-small profile-header-text">{props.tweetCount} tweets</p>
            </div>
        </div>
    )
}

export default Header;