import { AppBar, Toolbar, IconButton, Box, styled, Fab, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../store/currentUserSlice";
import { RootState } from "../../store/store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -5,
    left: 0,
    right: 0,
    margin: '0 auto',
});

const HOME_ROUTE = '/home'

const BottomNav: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
    const { logout, isAuthenticated, loginWithRedirect } = useAuth0();
    const currentUser = useSelector((state: RootState) => getCurrentUser(state));
    const navigate = useNavigate();
    const location = useLocation().pathname;

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateToChat = () => {
        navigate("/post/new");
    }

    return (
        <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar>
                <IconButton component={Link} to={HOME_ROUTE} color="inherit" >
                    {
                        location === HOME_ROUTE ?
                            <HomeIcon fontSize="large" />
                            :
                            <HomeOutlinedIcon fontSize="large" />
                    }
                </IconButton >
                <StyledFab onClick={() => navigateToChat()} color="primary" aria-label="add" size="large">
                    <CreateIcon style={{ color: 'white' }} />
                </StyledFab>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton component={Link} to={`/${currentUser.userHandle}`} color="inherit">
                    {
                        location === `/${currentUser.userHandle}` ?
                            <PersonIcon fontSize="large" /> :
                            <PersonOutlineOutlinedIcon fontSize="large" />
                    }
                </IconButton>
                <IconButton color="inherit" onClick={handleMenuClick}>
                    <MoreIcon fontSize="large" />
                </IconButton>
            </Toolbar>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    isAuthenticated
                        ?
                        <MenuItem onClick={() => logout({ logoutParams: { returnTo: logoutUrl } })}>
                            Logout
                        </MenuItem>
                        :
                        <MenuItem onClick={() => loginWithRedirect()} >
                            Login
                        </MenuItem>
                }
                <MenuItem onClick={handleClose}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Information Page
                    </Link>
                </MenuItem>
            </Menu>
        </AppBar >
    );
}

export default BottomNav;