import { FC } from "react";
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import TwitterIcon from '@mui/icons-material/Twitter';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CreateIcon from '@mui/icons-material/Create';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import './drawer.css';
import { getCurrentUser } from "../../store/currentUserSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useAuth0 } from "@auth0/auth0-react";

const SideDrawer: FC = () => {

    const navigate = useNavigate();

    const currentUser = useSelector((state: RootState) => getCurrentUser(state));

    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const logoutUrl = process.env.REACT_APP_LOGOUT_URL;

    const navValues: any[][] = [
        [<TwitterIcon fontSize="large" className="twitter-icon" />, <TwitterIcon fontSize="large" className="twitter-icon" />, '/home', ''],
        [<HomeIcon fontSize="large" className="list-icon" />, <HomeOutlinedIcon fontSize="large" className="list-icon" />, '/home', 'Random Post'],
        [<PersonIcon fontSize="large" className="list-icon" />, <PersonOutlineOutlinedIcon fontSize="large" className="list-icon" />, `/${currentUser.userHandle}`, 'Profile'],
        //[<BookmarkIcon fontSize="large" className="list-icon" />, <BookmarkBorderOutlinedIcon fontSize="large" className="list-icon" />, '/bookmarks', 'Bookmarks']

    ]

    const location = useLocation().pathname;

    async function login() {
        await loginWithRedirect();
    }

    function logoutButton() {
        logout({ logoutParams: { returnTo: logoutUrl } });
    }

    return (
        <Box sx={{
            position: 'sticky',
            flexGrow: 1, // Ensure full width
            width: '90%',
            height: '90vh', // Full viewport height
            top: 0,
            padding: 2,
            margin: 2
        }}>
            <List className="list" sx={{ flexGrow: 1 }}>
                {navValues.map((navValue, index) => (
                    <ListItem
                        key={index}
                        disablePadding
                        className=
                        {
                            location.indexOf(navValue[2]) > -1 && navValue[3] !== '' ? 'button selected-button nav-button' : 'button unselected-button nav-button'
                        }
                        sx={{ flexGrow: 1 }}
                    >
                        <ListItemButton
                            component={Link}
                            to={navValue[2]}
                            className="list-button"
                            sx={{ width: '90%' }}

                        >
                            <ListItemIcon >
                                {location.indexOf(navValue[2]) > -1 ? navValue[0] : navValue[1]}
                            </ListItemIcon>
                            <ListItemText
                                primary={navValue[3]}
                                disableTypography
                                className="text-size-nav"
                                sx={{
                                    display: { xs: 'none', lg: 'block' },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem className={'button unselected-button nav-button'} disablePadding>
                    <ListItemButton

                        onClick={!isAuthenticated ? login : logoutButton}
                        className="list-button"
                    >
                        <ListItemIcon >
                            {!isAuthenticated ? <LoginIcon fontSize="large" className="list-icon" /> : <LogoutIcon fontSize="large" className="list-icon" />}
                        </ListItemIcon>
                        <ListItemText
                            primary={!isAuthenticated ? 'Login' : 'Logout'}
                            disableTypography
                            className="text-size-nav"
                            sx={{
                                display: { xs: 'none', lg: 'block' },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem className={"button chat-button"} disablePadding sx={{ width: { sm: '50%', md: '80%' } }}>
                    <ListItemButton onClick={() => navigate("/post/new")} >
                        <CreateIcon sx={{
                            display: { sm: 'block', md: 'none' }
                        }} className="icon" />
                        <ListItemText
                            primary={"New Chat"}
                            disableTypography
                            className="text-size-nav bold new-chat-button"
                            sx={{
                                display: { sm: 'none', md: 'block' },
                            }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box >
    );
}

export default SideDrawer;