import { FC } from "react";
import Modal from "../../global/modal/modal";
import LoginButton from "../../components/login-button/login-button";
import "./login-modal.css"

const LoginModal: FC = () => {

    return (
        <Modal >
            <div className="login-modal-button">
                <LoginButton />
            </div>
        </Modal>
    )
}

export default LoginModal;