import { Box, Tabs, Tab } from "@mui/material";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import React from "react";
import "./profile-nav-bar.css"
import {
    Link,
    matchPath,
    useLocation,
} from 'react-router-dom';
import PreviousPosts from "../previous_posts/previous-posts";

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string | undefined;
}

function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function selectedTab(currentTab: string | undefined, tab: string): boolean {
    return currentTab === tab;
}

interface IProfileNavBarProps {
    userHandle: string;
}

export default function ProfileNavBar(props: IProfileNavBarProps) {

    const userHandle = props.userHandle;
    const routeMatch = useRouteMatch([`/${userHandle}`, `/${userHandle}/likes`, `/${userHandle}/dislikes`]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box>
            <TabsUnstyled>
                <Tabs value={currentTab} variant="fullWidth" TabIndicatorProps={{ style: { background: "none" } }}>
                    <Tab sx={{ background: 'none' }} label="Questions" value={`/${userHandle}`} to={`/${userHandle}`} component={Link} className={selectedTab(currentTab, `/${userHandle}`) ? "selected-tab" : "unselected-tab"} />
                    <Tab label="Likes" value={`/${userHandle}/likes`} to={`/${userHandle}/likes`} component={Link} className={selectedTab(currentTab, `/${userHandle}/likes`) ? "selected-tab" : "unselected-tab"} />
                    <Tab label="Dislikes" value={`/${userHandle}/dislikes`} to={`/${userHandle}/dislikes`} component={Link} className={selectedTab(currentTab, `/${userHandle}/dislikes`) ? "selected-tab" : "unselected-tab"} />
                </Tabs>
            </TabsUnstyled>
            <TabPanel index={`/${userHandle}`} value={currentTab}>
                <PreviousPosts userHandle={props.userHandle} />
            </TabPanel>
            <TabPanel index={`/${userHandle}/likes`} value={currentTab}>Coming Soon!</TabPanel>
            <TabPanel index={`/${userHandle}/dislikes`} value={currentTab}>Coming Soon!</TabPanel>

        </Box>
    );
}