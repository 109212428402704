import IPersonDTO from "../models/person/IPersonDTO";
import IUpdatePersonDTO from "../models/person/IUpdatePersonDTO";
import { IUserHandleAvailableDTO } from "../models/person/IUserHandleAvailableDTO";

import { patch, get } from "./api-client"

const SERVER_URL = process.env.REACT_APP_ORCHESTRATOR_URL
const PERSON_SERVER_URL = `${SERVER_URL}/v1/person`

export const updateCustomer = (patchCustomerDTO: IUpdatePersonDTO, bearerToken: string): Promise<IPersonDTO> => {
    return patch(PERSON_SERVER_URL, patchCustomerDTO, bearerToken);
}

// export const getPerson = (bearerToken: string): Promise<IPersonDTO> => {
//     return get(LOCALHOST_SERVER_URL, undefined, bearerToken);
// }

export const getOrCreatePerson = (bearerToken: string): Promise<IPersonDTO> => {
    return get(`${PERSON_SERVER_URL}`, undefined, bearerToken);
}

export const isUserHandleAvailable = (bearerToken: string, userHandle: string): Promise<IUserHandleAvailableDTO> => {
    return get(`${PERSON_SERVER_URL}/user-handle/${userHandle}`, undefined, bearerToken);
}