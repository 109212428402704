import { FC } from "react"
import { Container, Box, Typography } from "@mui/material";

const Home: FC = () => {
    return (
        <div>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        padding: '2rem',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '8px',
                        boxShadow: 3,
                        textAlign: 'center',
                    }}
                >

                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome to Share the AI!
                    </Typography>
                    <Typography variant="h5" paragraph>
                        This is a space where your AI conversations come to life. Whether you've had a deep, insightful chat or just a fun exchange, this platform lets you share those moments with the world. At Share the AI, we celebrate the blend of knowledge, creativity, and curiosity that AI brings into our lives, creating a community where these conversations can be explored and appreciated by everyone.
                    </Typography>
                    <Typography variant="h5" paragraph>
                        The site is still a work in progress, so you might encounter some quirks along the way. If you spot anything that needs fixing, please don't hesitate to let me know—I truly value your feedback. Together, we can make this platform a smooth, enjoyable experience for sharing AI conversations.
                    </Typography>
                    <Typography variant="h5" paragraph>
                        A little about me: I’m Seth, a software engineer from Seattle. My passion for technology and AI inspired me to create this platform, where I can combine my love for software development with the fascinating world of artificial intelligence. I’m always looking to learn and improve, so your input is incredibly valuable as I continue to build and refine this site.
                    </Typography>

                </Box>
            </Container>

        </div>
    )
}

export default Home;